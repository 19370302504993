import Api from './Api'

export default {
    // New Saif
    getOrgByID(payload) {
        return Api().post('api/dashuser/GetOrganizationByID', payload)
    },
    addOrganization(body) {

        return Api().post('api/dashuser/AddOrg', body)
    },
    getOrganization() {
        let payload = {
            "Source": "cineco",
            "OrganizationID": "3"
        }
        return Api().post('api/dashuser/GetOrgList', payload)
    },
    getOrganizationDDL() {
        let payload = {
            "Source": "cineco",
            "OrganizationID": "3"
        }
        return Api().post('api/dashuser/GetddlOrgList', payload)
    },
}